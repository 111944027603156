import * as React from 'react';
import { useSelector } from 'react-redux';

import { PLATE_TYPE } from "../../shared/constants";
import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectDisplayWithInfoRow } from '../../redux/selectors';
import { selectPlateFullData } from '../../selectors/PlateSelectors';

export function PrepareOrdersTable() {
  const orders: Order[] = useSelector(selectDisplayWithInfoRow(order => order.approve_date && !order.cancel_date && !order.installation_date));
  const plates: Plate[] = useSelector(selectPlateFullData());
  
  const allOrders = orders.map(order => ({
    ...order,
    plates: plates.filter(plate => plate.order_id === order.id)
  }));

  const rows = allOrders.filter(order => order.plates.length < 0 || order.plates.every(plate => plate.current_stage === PLATE_TYPE.PREPARE));

  return (
    <MetaDataTable
      title="Prepare"
      actionType="prepare"
      rows={rows}
      pageSubtitle
      addExtraInfo
      withFlag
    />
  );
}
